<template>
    <div>

    <el-main style=" padding-top: 5px;padding-bottom: initial  ">
        <el-row>
            <el-col :lg="24">
                <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                    <el-row>
                        <el-col :lg="24" style="line-height: 27px;">
                            <strong style=" font-size: 16px; "><a style="color: red">{{role.name}}</a> 角色分配</strong>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col :lg="11" v-for="(value,key) in permissionMap" :key="key" :offset="1">
                <div class="warning">
                    <el-row>
                        <el-col :lg="24" style="line-height: 27px;">
                            <strong style=" font-size: 16px; ">{{key}}</strong>
                            <vxe-switch v-model="permissionMap[key][0].assigned" @change="allSwitch(key)"></vxe-switch>
                        </el-col>
                    </el-row>
                </div>
                <vxe-grid
                          border
                          resizable
                          keep-source
                          ref="xGrid"
                          highlight-hover-row
                          :show-header=false
                          :mouse-config="{selected: false}"
                          :edit-config="{trigger: 'click', mode: 'cell'}"
                          :data="permissionMap[key]"
                          :columns="tableColumn"
                          :keyboard-config="{enterToTab:false,isArrow: true, isDel: false, isEnter: false, isTab: true,isEdit:true}">
                        <template #operate="{row}">
                            <template>
                                <vxe-switch v-model="row.assigned" @change="changeRoleEvent({row})"></vxe-switch>
                                <vxe-button icon="el-icon-delete" title="删除" circle @click="removeEvent(row)"></vxe-button>
                            </template>
                        </template>
                </vxe-grid>
            </el-col>
        </el-row>
        <br>
    </el-main>

    </div>
</template>

<script>

    export default {

        data() {
            return {
                assignedList:[],
                role_id:'',
                role: {},
                permissionMap: {},
                showEdit: false,
                dialogFormVisible: false,
                tableColumn: [
                    {field:'id', title: 'ID', width: 40},
                    {field:'action_key', width: 220 ,title: '名称'},
                    {field:'remark', width: 280 ,title: '备注', editRender: {name: 'input', events: {blur: this.remarkEvent}}},
                    {field:'operate',title: '操作',width: 120 , slots: {default: 'operate'}}
                ],
           }
       },

        methods: {
            allSwitch(key){

               const b = this.permissionMap[key][0].assigned;
                this.assignedList = [];
                this.permissionMap[key].forEach(row => {
                    row.assigned = b;
                    this.assignedList.push(row.id);
                    //this.changeRoleEvent({row});
               })
                 this.$axios({
                        method:'post',
                        url:'/admin/role/assignPermissionSwitchListVue',
                        data: {
                            role_id:this.role_id,
                            assignedList:this.assignedList,
                            checked:b
                       }
                }).then((response) =>{         //这里使用了ES6的语法
                     //console.log(response)       //请求成功返回的数据
                     if(response.status === 200 && response.data.state == 'ok'){
                        this.$message({
                             showClose: true,
                             message: response.data.msg,
                             type: 'success'
                        });
                         //row.assigned = !row.assigned
                    }else{
                         this.$message({
                             showClose: true,
                             message: response.data.msg,
                             type: 'error'
                        });
                         return
                    }
                }).catch((error) =>{
                     console.log(error)
                });
           },
            changeRoleEvent ({row}) {
                //console.log(row);
                row.assigned = row.assigned == false ? true : false
                this.$axios({
                    method:'post',
                    url:'/admin/role/assignPermissionSwitchVue',
                    params: {
                        role_id:this.role_id,
                        permission_id:row.id,
                        checked:!row.assigned
                   }
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.$message({
                            showClose: true,
                            message: '修改成功',
                            type: 'success'
                       });
                        row.assigned = !row.assigned
                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) =>{
                    console.log(error)
               });
           },
            remarkEvent (value, $event) {
                /*console.log(value)
                //console.log(value.row)
                //console.log($event)
                //console.log($event.target.value)*/
                this.$axios({
                    method:'post',
                    url:'/admin/permission/updateVue',
                    params: {
                        id:value.row.id,
                        remark:$event.target.value,
                   }
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.$message({
                            showClose: true,
                            message: '修改成功',
                            type: 'success'
                       });
                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) =>{
                    console.log(error)
               });
           },
            editRowEvent(row){
                this.showEdit = true;
                this.dialogFormVisible = true;
                this.roleForm.name ='';
                this.$axios({
                    method:'post',
                    url:'/admin/role/editVue',
                    params: {
                        id:row.id
                   }
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.roleForm.name = response.data.role.name;
                        this.roleForm.remark =  response.data.role.remark;
                        this.roleForm.id =  response.data.role.id;
                   }
               }).catch((error) =>{
                    console.log(error)
               });
           },
            assignPermissionsVue(){
                this.$axios({
                    method:'post',
                    url:'/admin/role/assignPermissionsVue',
                    params:{
                        id:this.role_id
                   }
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200) {
                        this.role = response.data.role
                        this.permissionMap = response.data.permissionMap
                   }
               }).catch((error) =>{
                    console.log(error)
               });
           },
            removeEvent (row) {
                this.$XModal.confirm('您确定要删除该数据?').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        //传送删除动作
                        this.$axios({
                            method:'post',
                            url:'/admin/permission/deleteVue',
                            params: {
                                id:row.id
                           }
                       }).then((response) =>{         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if(response.status === 200 && response.data.state == 'ok'){
                                this.$message({
                                    showClose: true,
                                    message: '删除成功',
                                    type: 'success'
                               });
                                this.assignPermissionsVue();
                           }else{
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) =>{
                            console.log(error)
                       });
                   }
               })
           },
            saveRole(formName){
                let url = '';
                if(this.showEdit){
                    url = '/admin/role/updateVue';
               }else{
                    url = '/admin/role/saveVue';
               }
                this.$refs[formName].validate((valid) => {
                    if(valid){
                        this.$axios({
                            method:'post',
                            url:url,
                            params: this.roleForm,
                       }).then((response) =>{         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if(response.status === 200 && response.data.state == 'ok'){
                                this.$message({
                                    showClose: true,
                                    message: '保存成功',
                                    type: 'success'
                               });
                                this.dialogFormVisible = false;
                                //this.getRoleListPageVue();
                           }else{
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) =>{
                            console.log(error)
                       });
                   }else {
                    //console.log('error submit!!');
                    return false;
                   }
               });
           },
       },
        created () {
            this.role_id = this.$route.query.id
            this.assignPermissionsVue();
       }
   };


</script>

<style scoped>
    .header-wrapepr {display:flex; flex-direction:row;justcontent:space-between}
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }
    .el-form-item__content .el-input{
        width: 130px;
   }
    .el-form-item__content .el-input{
        width: 60% !important;
   }
    /deep/ .el-form-item__error{
        position: initial !important;
   }
    .el-tag + .el-tag {
        margin-left: 10px;
        margin-top: 10px;
   }
</style>
